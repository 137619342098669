import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { FeaturesStyles } from "./FeaturesStyles"
import Feature from "./Feature"

const getProducts = graphql`
  query {
    featuredProducts: allContentfulEdm(filter: { featured: { eq: true } }) {
      edges {
        node {
          name
          series
          productCategory
          excerpt
          contentful_id
          slug
          images {
            gatsbyImageData(width: 600, formats: [AUTO, WEBP])
          }
        }
      }
    }
  }
`

const Features = () => {
  const response = useStaticQuery(getProducts)
  const products = response.featuredProducts.edges

  return (
    <FeaturesStyles>
      <div className="features__container">
        <h2 className="features__title">
          인기 상품 <span>.</span>
        </h2>
        <div className="features__container--scroll">
          {products.map(({ node }) => {
            return <Feature feature={node} />
          })}
        </div>
      </div>
    </FeaturesStyles>
  )
}

export default Features
